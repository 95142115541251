import React from 'react';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import Slider from 'react-slick';
import TextLoop from 'react-text-loop';
import Helmet from '../component/common/Helmet';
import FooterTwo from '../component/footer/FooterTwo';
import HeaderThree from '../component/header/HeaderThree';
import TabTwo from '../elements/tab/TabTwo';
import { CertificationList } from '../page-demo/Demo';
import { innerPageDemo } from '../page-demo/script';

const SlideList = [
	{
		textPosition: 'text-center',
		category: 'Welcome to my Page',
		description: '',
		buttonText: '',
		buttonLink: '',
	},
];
const PortfolioLanding = () => {
	let title = 'About Me',
		description = `Hello I am a professional Cloud Solution Architect with more than ${
			new Date().getFullYear() - 2020
		} years of experience designing and implementing both architectural and software solutions. Experienced in developing Cloud-based, On-premises software solutions, Web Applications, and Back-end applications. Currently, also delivering AWS Courses as an Authorized AWS Instructor to students to share the experience and excitement about the cloud.`;
	return (
		<div className='active-dark'>
			<Helmet pageTitle='alperen-kayaalti' />

			<HeaderThree homeLink='/' logo='symbol-dark' color='color-black' />
			{/* Start Slider Area   */}
			<div id='home' className='fix'>
				<div className='slider-wrapper'>
					{/* Start Single Slide */}
					{SlideList.map((value, index) => (
						<div
							className='slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--35'
							key={index}
						>
							<div className='container'>
								<div className='row'>
									<div className='col-lg-12'>
										<div className={`inner ${value.textPosition}`}>
											{value.category ? <span>{value.category}</span> : ''}
											<h1 className='title'>
												Hi, I’m Alperen Kayaaltı <br />
												<TextLoop>
													<span>Cloud Solution Architect</span>
													<span>Champion - AAI</span>
													<span>Backend Engineer</span>
												</TextLoop>{' '}
											</h1>
											<h2>based in Türkiye.</h2>
											{value.description ? (
												<p className='description'>{value.description}</p>
											) : (
												''
											)}
											{value.buttonText ? (
												<div className='slide-btn'>
													<a
														className='rn-button-style--2 btn-primary-color'
														href={`${value.buttonLink}`}
													>
														{value.buttonText}
													</a>
												</div>
											) : (
												''
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
					{/* End Single Slide */}
				</div>
			</div>
			{/* End Slider Area   */}

			{/* Start About Area */}
			<div id='about' className='fix'>
				<div className='about-area ptb--120  bg_color--1'>
					<div className='about-wrapper'>
						<div className='container'>
							<div className='row row--35 align-items-center'>
								<div className='col-lg-5'>
									<div className='thumbnail'>
										<img
											className='w-100'
											src='/assets/images/bg/bg-image-36.jpg'
											alt='About Images'
										/>
									</div>
								</div>
								<div className='col-lg-7'>
									<div className='about-inner inner'>
										<div className='section-title'>
											<h2 className='title'>{title}</h2>
											<p className='description'>{description}</p>
										</div>
										<div className='row mt--30'>
											<TabTwo tabStyle='tab-style--1' />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* End About Area */}

			{/* Start Certifications Area */}

			<div
				id='certifications'
				className='home-demo-area bg_color--6 pt--120 pb--130 pt_md--80 pb_md--90 pt_sm--80 pb_sm--90 fix'
			>
				<div className='wrapper plr--120'>
					<div className='row'>
						<div className='col-lg-12'>
							<div className='section-title text-center pb--30'>
								<h2 className='theme-gradient'>My Certifications</h2>
								<p>
									These certifications serve as a testament to my expertise,
									reflecting a commitment to continuous learning and growth in
									my journey.
								</p>
							</div>
						</div>
					</div>

					<div className='row row--0'>
						<div className='col-lg-12'>
							<Slider
								className='inner-demo-activation rn-slick-dot slick-gutter-15'
								{...innerPageDemo}
							>
								{CertificationList.map((value, index) => (
									// Start Single Demo
									<div key={index} className='single-slide'>
										<div className='single-demo'>
											<a
												target='_blank'
												rel='noopener noreferrer'
												href={`${value.url}`}
											>
												<img
													src={`/assets/images/certifications/${value.imageUrl}`}
													alt='Certificate'
												/>
												<h3 className='title text-white text-center text-lg'>
													{value.title}
												</h3>
											</a>
										</div>
									</div>
									// End Single Demo
								))}
							</Slider>
						</div>
					</div>
				</div>
			</div>
			{/* End Certifications Area */}

			{/* Start Service Area  */}
			{/* <div id='service' className='fix'>
				<div className='service-area creative-service-wrapper ptb--120 bg_color--5'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<div className='section-title text-center service-style--3 mb--30 mb_sm--0'>
									<h2 className='title'>My Awesome Service</h2>
									<p>
										There are many variations of passages of Lorem Ipsum
										available, but the majority have suffered alteration.
									</p>
								</div>
							</div>
						</div>
						<div className='row creative-service'>
							<div className='col-lg-12'>
								<ServiceList
									item='6'
									column='col-lg-4 col-md-6 col-sm-6 col-12 text-left'
								/>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* End Service Area  */}

			{/* Start Portfolio Area */}
			{/* <div id='portfolio' className='fix'>
				<div className='portfolio-area ptb--120 bg_color--1'>
					<div className='portfolio-sacousel-inner'>
						<div className='container'>
							<div className='row'>
								<div className='col-lg-12'>
									<div className='section-title text-center service-style--3 mb--30 mb_sm--0'>
										<h2 className='title'>My Latest Project</h2>
										<p>
											There are many variations of passages of Lorem Ipsum
											available, but the majority have suffered alteration.
										</p>
									</div>
								</div>
							</div>
							<div className='row'>
								<PortfolioList
									styevariation='text-center mt--40'
									column='col-lg-4 col-md-6 col-sm-6 col-12'
									item='6'
								/>
							</div>
							<div className='row'>
								<div className='col-lg-12'>
									<div className='view-more-btn mt--60 mt_sm--30 text-center'>
										<a className='rn-button-style--2 btn-solid' href='/blog'>
											<span>View More</span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
			{/* End Portfolio Area */}

			{/* Start Blog Area */}
			{/* <div id='blog' className='fix'>
				<div className='rn-blog-area ptb--120 bg_color--5 mb-dec--30'>
					<div className='container'>
						<div className='row align-items-end'>
							<div className='col-lg-12 col-md-12 col-sm-12 col-12'>
								<div className='section-title text-center'>
									<h2>Latest News</h2>
									<p>
										There are many variations of passages of Lorem Ipsum
										available, <br />
										but the majority have suffered alteration.
									</p>
								</div>
							</div>
						</div>
						<div className='row mt--60 mt_sm--40'>
							{PostList.map((value, i) => (
								<div className='col-lg-4 col-md-6 col-12' key={i}>
									<div className='blog blog-style--1'>
										<div className='thumbnail'>
											<a href='/blog-details'>
												<img
													className='w-100'
													src={`/assets/images/blog/blog-${value.images}.jpg`}
													alt='Blog Images'
												/>
											</a>
										</div>
										<div className='content'>
											<p className='blogtype'>{value.category}</p>
											<h4 className='title'>
												<a href='/blog-details'>{value.title}</a>
											</h4>
											<div className='blog-btn'>
												<a className='rn-btn text-white' href='/blog-details'>
													Read More
												</a>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div> */}
			{/* End Blog Area */}

			{/* Start Contact Area */}
			{/* <div id='contact' className='fix'>
				<div className='rn-contact-area ptb--120 bg_color--1'>
					<ContactThree
						contactImages='/assets/images/about/about-9.jpg'
						contactTitle='Reach out to me.'
					/>
				</div>
			</div> */}
			{/* End COntact Area */}

			<FooterTwo />
			{/* Start Back To Top */}
			<div className='backto-top'>
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>
			{/* End Back To Top */}
		</div>
	);
};

export default PortfolioLanding;
