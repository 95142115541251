import React from 'react';
import { FaLinkedinIn, FaGithub } from 'react-icons/fa';
import { SiCredly } from 'react-icons/si';

const SocialShare = [
	{
		Social: <FaGithub size='20' />,
		link: 'https://github.com/alperenkayaalti',
	},
	{
		Social: <FaLinkedinIn />,
		link: 'https://www.linkedin.com/in/alperenkayaalti/',
	},
	{
		Social: <SiCredly size='40' />,
		link: 'https://www.credly.com/users/semih-alperen-kayaalti/',
	},
];

const FooterTwo = () => {
	return (
		<div
			className='footer-style-2 ptb--30 bg_image bg_image--1'
			data-black-overlay='6'
		>
			<div className='wrapper plr--50 plr_sm--20'>
				<div className='row align-items-center justify-content-between'>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<div className='inner'>
							<div
								className='logo text-center text-sm-left mb_sm--20'
								style={{ width: '30%' }}
							>
								<a href='/'>
									<img
										src='/assets/images/logo/alperenkayaalti.gif'
										style={{ borderRadius: '40%' }}
										alt='Logo images'
									/>
								</a>
							</div>
						</div>
					</div>
					<div className='col-lg-4 col-md-6 col-sm-6 col-12'>
						<div className='inner text-center'>
							<ul className='social-share rn-lg-size d-flex justify-content-center liststyle'>
								{SocialShare.map((val, i) => (
									<li key={i}>
										<a href={`${val.link}`}>{val.Social}</a>
									</li>
								))}
							</ul>
						</div>
					</div>
					<div className='col-lg-4 col-md-12 col-sm-12 col-12'>
						<div className='inner text-lg-right text-center mt_md--20 mt_sm--20'>
							<div className='text'>
								<p>Copyright © 2022 Rainbow-Themes. All Rights Reserved.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default FooterTwo;
