import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component {
	render() {
		let tab1 = 'Main skills',
			tab2 = 'Education',
			tab3 = 'Experience';

		const { tabStyle } = this.props;
		return (
			<div>
				{/* Start Tabs Area */}
				<div className='tabs-area'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-12'>
								<Tabs>
									<TabList className={`${tabStyle}`}>
										<Tab>{tab1}</Tab>
										<Tab>{tab2}</Tab>
										<Tab>{tab3}</Tab>
									</TabList>

									<TabPanel>
										<div className='single-tab-content'>
											<ul>
												<li>
													<a href='#service'>
														Cloud Solution Architect <span> - AWS</span>
													</a>
													As an experienced AWS Cloud Solutions Architect, I
													have collaborated with both international and local
													clients to implement highly available and secure
													workloads on AWS. Leveraging my expertise in cloud
													architecture and best practices, I have designed
													resilient solutions that meet the unique needs of each
													client. My focus is always on delivering optimal
													performance and cost efficiency while ensuring the
													highest levels of security and scalability.
												</li>
												<li>
													<a href='#service'>
														Backend Engineer
														<span> - Development</span>
													</a>
													As a successful and capable backend engineer, I
													leverage my expertise in languages such as Python,
													NodeJs, TypeScript, and C# to develop scalable,
													efficient, and secure server-side applications and
													APIs that support web and mobile applications. I
													collaborate with cross-functional teams to ensure
													seamless integration and optimal performance of the
													tech stack. My goal is to design systems that meet the
													needs of users and the business.
												</li>
												<li>
													<a href='#service'>
														Authorized AWS Instructor <span> - Champion</span>
													</a>
													As an accomplished AWS Instructor with champion
													status, I have trained hundreds of individuals in the
													cloud through courses delivered in English and
													Turkish. My expertise in AWS services has enabled
													learners to leverage the full potential of cloud
													technology to meet their business needs.
												</li>
											</ul>
										</div>
									</TabPanel>

									<TabPanel>
										<div className='single-tab-content'>
											<ul>
												<li>
													<a href='#service'>
														BSc In CSE
														<span>
															{' '}
															- Istanbul Technical University, Istanbul, Türkiye
														</span>
													</a>{' '}
													2021
												</li>
											</ul>
										</div>
									</TabPanel>

									<TabPanel>
										<div className='single-tab-content'>
											<ul>
												<li>
													<a href='#service'>
														Cloud Solution Architect & Authorized AWS Instructor
														<span> - Yönetim.Academy</span>
													</a>{' '}
													2020 - Current
												</li>
												<li>
													<a href='#service'>
														IT CF&HR Intern <span> - Mercedes-Benz Türk</span>
													</a>{' '}
													2019 - 2020
												</li>
											</ul>
										</div>
									</TabPanel>
								</Tabs>
							</div>
						</div>
					</div>
				</div>
				{/* End Tabs Area */}
			</div>
		);
	}
}

export default TabsTwo;
